export function isDateTomorrow(dateTime: string | null): boolean {
  if (!dateTime) 
    return false;
  
  const tomorrowDate = new Date(new Date().setDate(new Date().getDate() + 1));
  
  return (new Date(dateTime)).toDateString() === tomorrowDate.toDateString();
}

export function isDateToday(dateTime: string | null): boolean {
  if (!dateTime) 
    return false;

  return (new Date(dateTime)).toDateString() === (new Date()).toDateString();
}

export function isFuture(dateTime: string | null): boolean {
  if (!dateTime) 
    return false;
  
  return new Date(dateTime) > new Date();
}

export function isPast(dateTime: string | null): boolean {
  if (!dateTime) 
    return false;
  
  return new Date() > new Date(dateTime);
}

export const dateMixin = {
  methods: {
    isDateTomorrow,
    isDateToday,
    isFuture,
    isPast,
  }
}
