<template>
  <v-form @submit.prevent="submit">
    <v-text-field
        id="login_email_input"
        :label="$t('email')"
        v-model="email"
        :rules="[v => !!v]"
        :error-messages="errorMessages"
    ></v-text-field>
    <v-text-field
        id="login_password_input"
        :label="$t('password')"
        type="password"
        v-model="password"
        :rules="[v => !!v]"
    ></v-text-field>
    <v-alert
        v-if="isPioneer"
        text
        prominent
        type="error"
        icon="mdi-shield-lock-outline"
    >
      {{ $t('login_pioneer_info') }}
    </v-alert>
    <v-btn
        id="login_submit_btn"
        :disabled="loginDisabled"
        :loading="loading"
        color="secondary"
        type="submit"
        @click="submit"
    >
      {{ $t('login') }}
    </v-btn>
  </v-form>
</template>

<script>
import auditLog from "@/bootstrap/auditLog";
import {PIONEER_STAGE, STAGE} from "@/bootstrap/config"
import { datadogRum } from "@datadog/browser-rum"
import store from "@/bootstrap/store";

export default {
  name: 'Login',
  data: () => ({
    email: null,
    password: null,
    errorMessages: [],
    loading: false
  }),
  computed: {
    loginDisabled() {
      return this.loading || !this.email || !this.password
    },
    isPioneer(){
      return STAGE === PIONEER_STAGE;
    },
  },
  methods: {
    async submit() {
      this.loading = true
      this.errorMessages = []
      try {
        const token = await this.$store.dispatch('core/fetchAccessToken',
            {
              email: this.email,
              password: this.password
            }
        )
        if (token) {
          await this.$store.dispatch('core/getAuthUser')
          auditLog.logSignIn()
          datadogRum.setUser({
            id: store.getters["core/getAuthUserId"],
          })
          this.$store.dispatch('core/redirect', { name: 'hub_selector' })
        } else {
          this.$store.dispatch('core/handleError', new Error(this.$t('could_not_retrieve_authentication_token')))
        }
      } catch (e) {
        if (e?.response?.status === 400 || e?.response?.status === 401) {
          this.errorMessages.push(this.$t('invalid_credentials'))
        } else {
          this.$store.dispatch('core/handleError', e)
        }
      }
      this.loading = false
    }
  }
}
</script>
